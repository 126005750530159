<template>
  <v-dialog
    v-model="modalData.dialog"
    persistent
    scrollable
    max-width="1250px"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="ml-4 mb-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("FORMS.new_role") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="formModel.name"
                :label="$t('FORMS.name')"
                :rules="nameRules"
                :id="dynamicID"
              ></v-text-field>
              <v-card-title>
                {{ $t("FORMS.users") }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('FORMS.search')"
                  single-line
                  hide-details
                  :id="dynamicID"
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="userHeaders"
                :items="formModel.users"
                :search="search"
              >
                <template #item.christian_name1="{ item }">
                  <router-link :to="`/settings/users/${item.id}`">
                    {{ item.christian_name1 }}
                  </router-link>
                </template>
                <template #item.surname="{ item }">
                  <router-link :to="`/settings/users/${item.id}`">
                    {{ item.surname }}
                  </router-link>
                </template>
                <template #item.email="{ value }">
                  <a :href="`mailto:${value}`">
                    {{ value }}
                  </a>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p style="font-size: 1.25rem; font-weight: 500">
                {{ $t("FORMS.permissions") }}
              </p>
              <v-expansion-panels multiple>
                <v-expansion-panel
                  v-for="(moduleName, i) in permissionModuleNames"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{ $t("PERMISSIONS." + moduleName) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span v-for="(item, i) in permissions" :key="i">
                      <!-- :disabled="formModel.id == superadminRoleId"  -->
                      <v-switch
                        v-if="
                          item.name.includes(moduleName) &&
                          item.name != 'sales.salesOrder.assign.group'
                        "
                        :key="i + 'perm'"
                        v-model="selectedPermissions"
                        @change="handleSwitcher(item)"
                        :label="
                          $t('PERMISSIONS.' + item.name.replaceAll('.', '_'))
                        "
                        :value="item"
                      ></v-switch>
                    </span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
          v-if="
            (permissionCan('create') && this.modalData.editedIndex === -1) ||
            permissionCan('update')
          "
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions> -->
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
// import i18nService from "@/core/services/i18n.service.js";

export const initialFormData = {
  id: "",
  name: "",
  guard_name: "",
  permissions: [],
  users: [],
};

export default {
  name: "RoleModalForm",
  props: ["modalData", "authPermissions"],
  data() {
    return {
      permissions: [],
      selectedPermissions: [],
      permissionModuleNames: [],
      search: "",
      formModel: initialFormData,
      userHeaders: [
        { text: this.$t("TABLE.first_name"), value: "christian_name1" },
        { text: this.$t("TABLE.christian_name2"), value: "christian_name2" },
        { text: this.$t("TABLE.last_name"), value: "surname" },
        { text: "Email", value: "email" },
      ],
      formValid: false,
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    formTitle() {
      return this.modalData.editedIndex === -1
        ? this.$t("MENU.NEW")
        : this.$t("FORMS.edit") + this.formModel.name;
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    superadminRoleId() {
      return process.env.VUE_APP_SUPERADMIN_ROLE_ID ?? 1;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.selectedPermissions = this.formModel.permissions;
      },
    },
  },
  methods: {
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.formModel.permissions = this.selectedPermissions;
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },

    findPermissionByName(name) {
      return this.permissions.find((item) => {
        return item.name == name;
      });
    },

    checkPermissionByName(model, name) {
      return (
        typeof this.selectedPermissions.find((item) => {
          return item && item.name == name;
        }) === "object"
      );
    },

    handleSwitcher(item) {
      let nameArray = item.name.split(".");
      console.log(nameArray);
      if (
        (nameArray[2] == "update" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "update"
          )) ||
        (nameArray[2] == "create" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "create"
          )) ||
        (nameArray[2] == "delete" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "delete"
          ))
      ) {
        if (
          !this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "view"
          )
        ) {
          this.selectedPermissions.push(
            this.findPermissionByName(
              nameArray[0] + "." + nameArray[1] + "." + "view"
            )
          );
        }
      }
      if (
        (nameArray[2] == "menu" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "menu"
          )) ||
        (nameArray[2] == "menu" &&
          // !this.checkPermissionByName(
          this.checkPermissionByName(
            this.selectedPermissions,
            "module." + nameArray[0] + "." + "menu"
          ))
      ) {
        this.selectedPermissions.push(
          this.findPermissionByName("module." + nameArray[0] + "." + "menu")
        );
      }

      if (
        (nameArray[2] == "approve" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "approve"
          )) ||
        (nameArray[2] == "contract" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "contract"
          )) ||
        (nameArray[2] == "assign" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "assign"
          )) ||
        (nameArray[2] == "revoke" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "revoke"
          )) ||
        (nameArray[2] == "lost" &&
          this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "lost"
          ))
      ) {
        if (
          !this.checkPermissionByName(
            this.selectedPermissions,
            nameArray[0] + "." + nameArray[1] + "." + "update"
          )
        ) {
          this.selectedPermissions.push(
            this.findPermissionByName(
              nameArray[0] + "." + nameArray[1] + "." + "update"
            )
          );
        }
      }
    },

    getPermissions() {
      ApiService.get("permission")
        .then(({ data }) => {
          this.permissions = data;
          this.permissions.forEach((element) => {
            let nameArray = element.name.split(".");
            if (this.permissionModuleNames.indexOf(nameArray[0]) === -1) {
              this.permissionModuleNames.push(nameArray[0]);
            }
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },
    permissionCan(action) {
      return this.authPermissions.find(
        (x) => x.name == "system.role." + action
      );
    },
  },
  async mounted() {
    this.getPermissions();
  },
};
</script>
